
import { Injectable } from '@angular/core';
import {
  Capacitor, Plugins,
  PushNotification, PushNotificationActionPerformed, PushNotificationToken
} from '@capacitor/core';
import { HttpService } from '../services/http.service';
const { PushNotifications } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class FcmService {

  constructor(private service: HttpService) { }

  initPush() {
    // this.registerPush();
    if (Capacitor.platform !== 'web') {
      this.registerPush();
    }
  }

  private registerPush() {
    PushNotifications.requestPermission().then((permission) => {
      if (permission.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // No permission for push granted
      }
    });

    PushNotifications.addListener(
      'registration',
      (token: PushNotificationToken) => {
    
        localStorage.setItem('pushToken', token.value);
        sessionStorage.setItem('pushToken', token.value)
        // this.service.registerPushToke(localStorage.getItem('pushToken'));
      }
    );

    PushNotifications.addListener('registrationError', (error: any) => {
      //   alert('Error: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotification) => {
        // alert('Push received: ' + JSON.stringify(notification));

      }
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: PushNotificationActionPerformed) => {
        const data = notification.notification.data;
        // alert('Action performed: ' + JSON.stringify(notification.notification));

        if (data && data.detailsId) {
   
        }
      }
    );
  }
}
