import { Component, OnInit } from '@angular/core';
import { PopoverController, NavController, NavParams, ModalController, } from '@ionic/angular';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';
import { ToasterServices } from 'src/app/core/services/toaster.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { ViewProductPage } from '../../message/view-product/view-product.page';
import { NavigationExtras } from '@angular/router';
import { AdjustPricePage } from '../../message/adjust-price/adjust-price.page';
import { EventStreamService, Events, EmitEvent } from '../../core/services/event-stream.service';

@Component({
  selector: 'app-chat-popover',
  templateUrl: './chat-popover.component.html',
  styleUrls: ['./chat-popover.component.scss'],
})
export class ChatPopoverComponent implements OnInit {
  messages: any;
  product: any;
  user: any;
  chatWith: any;
  channel: any;
  adjustPrice: any;
  eventSubscription: any;
  constructor(
    public router: NavController,
    public httpService: HttpService,
    public navParams: NavParams,
    public toasterService: ToasterServices,
    public popoverController: PopoverController,
    public storageService: StorageService,
    public modalController: ModalController,
    public eventStream: EventStreamService,
  ) {

  }

  ngOnInit() {
    const user = this.storageService.get('profile');
    if (typeof (user) === 'string') {
      this.user = JSON.parse(this.storageService.get('profile'));
    } else {
      this.user = this.storageService.get('profile');
    }

    if (this.navParams.data) {
      this.messages = this.navParams.data.message;
      this.product = this.navParams.data.product;
      this.chatWith = this.navParams.data.chatWith;
      this.channel = this.navParams.data.channel;
      this.adjustPrice = this.navParams.data.AdjustPrice
    }
  }

  async DismissClick() {
    await this.popoverController.dismiss();
  }

  removeAllMessages() {
    this.httpService.remove(environment.SOCKET_ENDPOINT + '/channelmessage/deleteall?channel_id=' + this.messages[0].channel_id + '&client_id=' + environment.CLIENT_ID).subscribe(data => {
      this.DismissClick()
    }, error => {
    });
  }



  async adjustPricePopup() {
    this.DismissClick();
    const modal = await this.modalController.create({
      component: AdjustPricePage,
      cssClass: 'my-custom-class'
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    
    if (data?.mazal) {
 
      this.eventStream.emit(new EmitEvent(Events.MAZAL, data));
    }
    // if(data['closed'] === true ) {
    //   this.getAllMessage();
    // }
  }

  archiveConversation() {
    this.channel.participants = this.channel.participants.map(obj => obj.email === this.chatWith.email ? { ...obj, status: 'Archive' } : obj);
    this.httpService.put(environment.SOCKET_ENDPOINT + '/channel/updatechannel', this.channel).subscribe(data => {
      setTimeout(() => {
        this.DismissClick();
        this.modalController.dismiss({ 'closed': true });
      }, 200);
    }, error => {
    });
  }

  viewProfile() {
    this.DismissClick();

    this.modalController.dismiss({ 'closed': true });
    this.storageService.set('userProfile', this.chatWith);
    setTimeout(() => {
      this.router.navigateForward('view-profile', this.chatWith);
    }, 50);

    // 
  }

  async viewProduct() {
    const modal = await this.modalController.create({
      component: ViewProductPage,
      componentProps: { address: this.product },
      cssClass: 'my-custom-class'
    });
    this.DismissClick();
    return await modal.present();
  }
}
