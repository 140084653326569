import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-view-product',
  templateUrl: './view-product.page.html',
  styleUrls: ['./view-product.page.scss'],
})
export class ViewProductPage implements OnInit {
  product: any = {};

  constructor(
    private modalController: ModalController,
    private navParams: NavParams
  ) { }

  ngOnInit() {
    let product = this.navParams.data;
    if (product.address && product.address.products && product.address.products.length > 0) {
      this.product = product.address.products[0];

    }
  }

  close() {
    this.modalController.dismiss();
  }
}
