import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { EventStreamService, Events, EmitEvent } from './event-stream.service';
import { environment } from 'src/environments/environment';
import * as io from 'socket.io-client';
@Injectable({
  providedIn: 'root'
})

export class StorageService {
  public socket;
  private storage: any = {};
  constructor(public router: NavController,
    public eventService: EventStreamService) { 
    this.socket = io(environment.SOCKET_ENDPOINT);
  }

  get(key) {
    let data = this.storage[key];
    if (!data) {
      let d = sessionStorage.getItem(key);
      if (d && d.indexOf("}") > -1) {
        data = JSON.parse(d);
      } else {
        data = d;
      }

      return data;
    } else {
      return data;
    }

  }

  set(key: string, data: any, storeInsessionStorage?: boolean) {
    this.storage[key] = data;
    if (storeInsessionStorage) {
      sessionStorage.setItem(key, JSON.stringify(data));
    }
  }

  remove(key) {
    this.storage[key] = '';
    sessionStorage.removeItem(key);
  }
  logout() {
    // let token = localStorage.getItem('pushToken');
    // this.http.put(`${environment.IDENTITY_ENDPOINT}/users/remove_push_token/${token}`, {}).subscribe(
    //   Response => {
    //     console.log(Response);
    //   })

    // localStorage.removeItem('pushToken');
    // setTimeout(() => {
    // this.even'tService.ResetEvent();
    this.socket.emit('logout',{userId: this.get('profile')});
    this.remove('totalUnread');
    this.remove('account-info');
    this.remove('cart-info');
    this.remove('chatWith');
    this.remove('tradey-session');
    this.remove('wireTransaction');
    this.remove('company-info');
    this.remove('shipping');
    this.remove('money-to');
    this.remove('money-request');
    this.remove('product');
    this.remove('user_id');
    this.remove('participants');
    this.remove('user');
    this.remove('totalUnread');
    this.storage['account-info'] = {};
    this.storage['user'] = {};
    delete this.storage['account-info'];
    delete this.storage['user'];
    sessionStorage.removeItem('user');
    this.storage = {};
    this.storage = new Object();
    this.router.navigateBack('/login');
    sessionStorage.clear();
    
    // this.storage 
    // }, 100);

  }
}
