import { Component, OnInit } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';
import { ToasterServices } from 'src/app/core/services/toaster.service';
import { StorageService } from 'src/app/core/services/storage.service';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-adjust-price',
  templateUrl: './adjust-price.page.html',
  styleUrls: ['./adjust-price.page.scss'],
})
export class AdjustPricePage implements OnInit {
  product: any = [];
  offerPrice: any;
  chatWith: any;
  constructor(public modalController: ModalController,
              public httpService: HttpService,
              public toasterService: ToasterServices,
              public storageService: StorageService
     ) { }
  ngOnInit() {
    this.product = this.storageService.get('product');
    this.chatWith = this.storageService.get('chatWith');
  }

  close() {
    this.modalController.dismiss();
  }

  keyUpChecker(event) {
    event.target.value = event.target.value.replace(/[^0-9,.]*/g, '');
    }

  ValidatePrice(value,event){
    if(this.offerPrice > value){
      this.offerPrice = 0;
      event.target.value = event.target.value.replace('');
      // this.toasterService.warningToast('Offer price must be less than diamond price');
    }    
  }

  // New code
  makeOffer(cart) {
    if (this.offerPrice > 0) {
      if (cart && cart?.products?.length > 0) {
        cart.products[0].offerPrice = this.offerPrice;
        this.modalController.dismiss({"mazal": cart.products[0].offerPrice});
      }
   
    }
  }
  // Old code ***/

//   getCartId(cart) {
//     this.httpService.get(environment.TRADING_ENDPOINT + '/cart/user/cart?user_id=' +this.chatWith?._id + '&product_id='+ cart.products[0]._id +'&status=draft').subscribe(Response => {
//       const data = JSON.parse(JSON.stringify(Response));
//       cart = data.content[0];
//       cart.products[0].offerPrice = this.offerPrice;
//       if(cart){
//       this.updateCart(cart);
//       } else {
//         this.toasterService.warningToast('This product no longer exist in buyers cart');
//       }
//     }, error => {
//     });
// }

//   makeOffer(cart) {
//     if(this.offerPrice>0){
//     if(cart && cart?.products?.length>0){
//       cart.products[0].offerPrice = this.offerPrice;
//     }
//     console.log('card id is', cart);
    
//     if(!cart._id){
//       this.getCartId(cart);
//     } else {
//       this.updateCart(cart);
//     }

    
//   }
// }

// updateCart(cart) {
//   this.httpService.put(environment.TRADING_ENDPOINT + '/cart/'+ cart._id, cart).subscribe(
//     Response => {
//       this.toasterService.successToast('Price updated');
//     }, error => {
//       this.toasterService.errorToast('Some error occurred. Please try again.');
//     });
//   this.modalController.dismiss();
// }
}
