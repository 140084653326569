import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToasterServices {

  constructor( public toastController: ToastController) { }

  async successToast(messageData) {
    const toast = await this.toastController.create({
      message: messageData,
      duration: 2000,
      color: 'success',
      animated: true,
    });
    toast.present();
  }

  async errorToast(messageData) {
    const toast = await this.toastController.create({
      message: messageData,
      duration: 2000,
      color: 'danger',
      animated: true,
    });
    toast.present();
  }

  async warningToast(messageData) {
    const toast = await this.toastController.create({
      message: messageData,
      duration: 2000,
      color: 'warning',
      animated: true,
    });
    toast.present();
  }

  async darkToast(messageData) {
    const toast = await this.toastController.create({
      message: messageData,
      duration: 2000,
      color: 'dark',
      animated: true,
    });
    toast.present();
  }
}
