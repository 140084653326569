import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SocketioService } from './socketio.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpService } from './services/http.service';
// import { MomentModule } from 'ngx-moment';
import { TimeagoModule, TimeagoClock } from 'ngx-timeago';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { Observable, of, from } from 'rxjs';
import { expand, delay, skip } from 'rxjs/operators';
import { CurrencyFormatterDirective } from './directive/currency-formatter.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { DatePipe } from '@angular/common'
import { BnNgIdleService } from 'bn-ng-idle'; // import bn-ng-idle service
import { BusinessComponent } from './business/business.component';
import { DevelopersComponent } from './developers/developers.component';
import { TradeyappComponent } from './tradeyapp/tradeyapp.component';
import { ContactUsComponent } from './tradeyapp/components/contact-us/contact-us.component';
import { FooterComponent } from './tradeyapp/components/footer/footer.component';
import { AboutTradeyComponent } from './tradeyapp/components/about-tradey/about-tradey.component';
import { OurPlatformComponent } from './tradeyapp/components/our-platform/our-platform.component';
import { SecurityFraudProtectionComponent } from './tradeyapp/components/security-fraud-protection/security-fraud-protection.component';
import { TradeyDemoComponent } from './tradeyapp/components/tradey-demo/tradey-demo.component';

import { Keyboard } from '@ionic-native/keyboard/ngx';
import { CountdownModule } from 'ngx-countdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PrivacyPolicyComponentComponent } from './privacy-policy-component/privacy-policy-component.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
export class MyClock extends TimeagoClock {
  tick(then: number): Observable<number> {
    return of(0)
      .pipe(
        expand(() => {
          const now = Date.now();
          const seconds = Math.round(Math.abs(now - then) / 1000);

          const period = seconds < 60 ? 1000 : 1000 * 60;

          return of(period).pipe(delay(period));
        }),
        skip(1)
      );
  }
}
@NgModule({
  declarations: [AppComponent, CurrencyFormatterDirective, BusinessComponent, TradeyappComponent,FooterComponent,TradeyDemoComponent,SecurityFraudProtectionComponent,ContactUsComponent,AboutTradeyComponent,OurPlatformComponent, DevelopersComponent, PrivacyPolicyComponentComponent, TermsAndConditionsComponent],
  entryComponents: [],
  imports: [BrowserModule, NgSelectModule, CountdownModule, CommonModule, IonicModule.forRoot({
    backButtonText: 'Back',
    mode: 'ios',
    scrollPadding: false,
    scrollAssist: false
  }),
    FormsModule, AppRoutingModule, HttpClientModule,ReactiveFormsModule,
    TimeagoModule.forRoot({
      clock: { provide: TimeagoClock, useClass: MyClock },
    }),
    BrowserAnimationsModule,
  ],
  providers: [
    StatusBar,
    // FormBuilder,
    BnNgIdleService,
    CurrencyFormatterDirective,
    SplashScreen,
    Keyboard,
    DatePipe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    SocketioService,
    HttpService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
