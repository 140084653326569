import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tradey-demo',
  templateUrl: './tradey-demo.component.html',
  styleUrls: ['./tradey-demo.component.scss'],
})
export class TradeyDemoComponent implements OnInit {
  @Output() type = new EventEmitter();
  constructor() { }

  ngOnInit() { }

  goTo(path) {
    this.type.emit(path);
  }
}
