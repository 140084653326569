

 export enum notification {
    event_type_signup = "CARD",
    ToAddresses = '["info@thetradey.com"]',
    websiteUrl = "https://thetradey.com/tabs/home",
    logo = "Tradey",
    link = "https://thetradey.com/tabs/home",
    supportEmail= "info@thetradey.com>",
    supportAddress= "602, Oriena Business Park, Wagle Estate, Thane(W)",
    sourceName = "Tradey <info@thetradey.com>",
    // supportEmail = "chaudharin1994@gmail.com",
    // supportAddress = "602, Oriena Business Park, Wagle Estate, Thane(W)",
    // sourceName = "Niteen chaudhari <chaudharin1994@gmail.com>"
}

