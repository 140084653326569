import { Component, OnInit } from '@angular/core';
import { AdjustPricePage } from '../adjust-price/adjust-price.page'
import { SocketioService } from '../../socketio.service';
import * as io from 'socket.io-client';
import { environment } from '../../../environments/environment';
// import { Subscription } from 'rxjs/Subscription';
import { EventStreamService, Events } from 'src/app/core/services/event-stream.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { HttpService } from 'src/app/services/http.service';
// import { IonInfiniteScroll } from '@ionic/angular';
// import { ViewProductPage } from './../view-product/view-product.page';
import { PopoverController } from '@ionic/angular';
import { ChatPopoverComponent } from '../../popover/chat-popover/chat-popover.component';
import { NavController, ModalController, IonContent } from '@ionic/angular';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.page.html',
  styleUrls: ['./notification.page.scss'],
})
export class NotificationPage implements OnInit {
  public chatWith: any;
  public socket;
  public user: any;
  public channelID: any;
  public channelInfo: any;
  public product: any;
  messageData: any;
  
  constructor(private router: NavController,
    public popoverController: PopoverController,
    public modalController: ModalController, public httpService: HttpService,
    private chatService: SocketioService, public eventStream: EventStreamService, public storageService: StorageService) {
    this.chatWith = this.storageService.get('chatWith');
    this.socket = io(environment.SOCKET_ENDPOINT);
    this.product = this.storageService.get('product');
  }

  ngOnInit() {
    const user = this.storageService.get('profile');
    if (typeof (user) === 'string') {
      this.user = JSON.parse(this.storageService.get('profile'));
    } else {
      this.user = this.storageService.get('profile');
    }
    this.createChannel();
   
    // console.log('message info is here cmameeeee', this.messageInfo.name);
  }
  ionViewDidEnter() {

  }
  modelchange(event) {
 
  }
  back() {
    this.modalController.dismiss({ 'closed': true });
  }
  createChannel() {

    
    let channelData: any = {
      "channel_name": '',
      "subtitle": '',
      "client_id": environment.CLIENT_ID,
      "user_id": this.user._id
    };
    if (this.chatWith.type === 'User') {
      channelData.participants = [
        { user_id: this.chatWith._id, email: this.chatWith.email, unreadCount: 0, type: 'User', status: 'Active' },
        { user_id: this.user._id, email: this.user.email, unreadCount: 0, type: 'User', status: 'Active' }
      ];
    } else {
      //add subtitle here
      let productName: any;
      if (this.product && this.product?.products.length > 0) {
        this.product.products.forEach(p => {
          productName = p?.shape + '-' + p?.size + '-' + p?.clarity;
          if (!productName) {
            productName = 'id:' + p.diamond_id;
          }
        });
      }
      channelData.subtitle = productName;
      channelData.product = this.product;
      if (this.storageService.get('participants')) {
        channelData.user_id = this.storageService.get('user_id');
        channelData.participants = this.storageService.get('participants');
        const isPresent = channelData.participants.some(name => name.email === this.user.email && name.type === 'Seller');
        if (isPresent) {
          this.user.type = "Seller";
        } else {
          this.user.type = "Buyer";
        }
      } else {
        channelData.participants = [
          { user_id: this.chatWith._id, email: this.chatWith.email, unreadCount: 0, type: 'Seller',isNotSeller: true },
          { user_id: this.user._id, email: this.user.email, unreadCount: 0, type: 'Buyer' }
        ];
      }
    }


    this.httpService.post(environment.SOCKET_ENDPOINT + '/channel/save', channelData).subscribe(
      Response => {
        this.channelInfo = Response['content'];
        this.channelID = this.channelInfo.channel_id;

 
        
        this.chatService.joinChannel(this.channelID);
      }, error => {

      });
  }

  sendMessage() {

    if (this.messageData) {
      const data = {
        channel_id: this.channelID, client_id: environment.CLIENT_ID,isnottradey: this.messageData, message: {
          body: this.messageData,
          from: { email: this.user.email, senderId: this.user._id, display_name: this.user.display_name || this.user.email },
          to: { email: this.chatWith.email, receiverId: this.chatWith._id, display_name: this.chatWith.display_name || this.chatWith.email },
          timestamp: new Date(Date.now()), status: [{ to: 'unread' }, { from: 'read' }]
        }
      }
      this.chatService.sendMessage(data);
      this.messageData = '';
      setTimeout(() => {
        this.modalController.dismiss({'closed': true});
        this.router.navigateForward('tabs/home');
      }, 200);
     
    }
  }

  

}
