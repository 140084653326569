import { Component, OnInit, ViewChild } from '@angular/core';
import { NavController, ModalController, IonContent } from '@ionic/angular';
import { AdjustPricePage } from '../adjust-price/adjust-price.page';
import { SocketioService } from '../../socketio.service';
import * as io from 'socket.io-client';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs/Subscription';
import { EventStreamService, Events } from 'src/app/core/services/event-stream.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { HttpService } from 'src/app/services/http.service';
import { IonInfiniteScroll } from '@ionic/angular';
import { ViewProductPage } from './../view-product/view-product.page';
import { PopoverController } from '@ionic/angular';
import { ChatPopoverComponent } from '../../popover/chat-popover/chat-popover.component';
import { ToasterServices } from 'src/app/core/services/toaster.service';
import { notification } from '../../constant/notification.enum';
import { Platform } from '@ionic/angular';
import { Keyboard } from '@ionic-native/keyboard/ngx';
@Component({
  selector: 'app-chat',
  templateUrl: './chat.page.html',
  styleUrls: ['./chat.page.scss'],
  host: {
    "(window:click)": "onClick()"
  }
})
export class ChatPage implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonContent) content: IonContent;
  private mutationObserver: MutationObserver;
  public whitespace: any;
  public message: any;
  public socket;
  public subscription: Subscription;
  public messages = [];
  public chatWith: any;
  public user: any;
  public channelID: any;
  public channelInfo: any;
  public show: boolean = false;
  public page = 1;
  public size = 10;
  public allMessageCount: any;
  public loader: any;
  product: any;
  cartInfo: any;
  adjustPricebtn = false;
  eventSubscription: any; 
  public spinner: boolean = true;
  public isUserActive: any;
  constructor(private router: NavController,public platform: Platform,public keyboard: Keyboard,
    public popoverController: PopoverController, public toasterService: ToasterServices,
    public modalController: ModalController, public httpService: HttpService,
    private chatService: SocketioService, public eventStream: EventStreamService, public storageService: StorageService) {

    this.subscription = this.chatService.getMessage().subscribe(message => {
      if(message?.message?.body.indexOf(this.user?.display_name + ' has chosen to buy at') === 0){
        message.message.body = message?.message?.body.replace(this.user?.display_name+ ' has', "You have");
      }
      this.messages.push(message);
      setTimeout(() => {
        this.updateScroll();
      }, 20);
    });

    this.eventSubscription = this.eventStream.on(Events.MAZAL, (u) => {
      if(u.mazal){
        this.mazalMessage(u);
      }
      });


    this.chatWith = this.storageService.get('chatWith');
    this.socket = io(environment.SOCKET_ENDPOINT);
    this.product = this.storageService.get('product');
    // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>',this.product);
    this.cartInfo = this.storageService.get('cart-info');
    this.socket.emit('isUserOnline',{userId: this.chatWith._id});
    this.socket.on('isUserOnline', (data) => {
      this.isUserActive = data;
    });
  }

  ngOnInit() {
    if (this.cartInfo) {
      this.cartInfo.forEach(d => {
        if (d.products[0]?._id === this.product?.products[0]?._id) {
          this.adjustPricebtn = true;
        }
      });
    }
    const user = this.storageService.get('profile');
    if (typeof (user) === 'string') {
      this.user = JSON.parse(this.storageService.get('profile'));
    } else {
      this.user = this.storageService.get('profile');
    }
    setTimeout(() => {
      this.updateScroll();
      // this.getAllMessage();
      this.createChannel();
      if(this.chatWith?.type != 'Seller' && this.product) {
      this.getCartId(this.product);
      }
    }, 300);




  }
  ionViewDidLoad() {
     setTimeout(() => {
        this.content.scrollToBottom(60);
     }, 800);
  }

  checkBrowser() {
    // var clientHeight = document.getElementById('dataHeight').clientHeight;
    // console.log(screen.height, clientHeight);
    // alert(clientHeight);
    if(this.platform.is('iphone') || this.platform.is('ios')){
      this.whitespace = true;
    }
    let device = this.platform.platforms();
    if(device.includes('iphone') || device.includes('ios')  ){
        this.whitespace = true;
    }
  }

  async chataOptions(ev: any) {
    const popover = await this.popoverController.create({
      component: ChatPopoverComponent,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
      componentProps: { message: this.messages, product: this.product, chatWith: this.chatWith, user: this.user, channel: this.channelInfo ,AdjustPrice: this.hideAdjustPrice}
    });
    return await popover.present();
  }


  createChannel() {
    let channelData: any = {
      "channel_name": '',
      "subtitle": '',
      "client_id": environment.CLIENT_ID,
      "user_id": this.user._id,
    };

    
    if (this.chatWith.type === 'User') {
      channelData.participants = [
        { user_id: this.chatWith._id, email: this.chatWith.email, unreadCount: 0, type: 'User', status: 'Active' },
        { user_id: this.user._id, email: this.user.email, unreadCount: 0, type: 'User', status: 'Active' }
      ];
    } else {
      //add subtitle here
      this.product = this.storageService.get('product');
 
      let productName: any;
      if (this.product && this.product?.products.length > 0) {
        this.product.products.forEach(p => {
          // + '-'  + p?.color
          productName = p?.shape + '-' + p?.size + '-' + p?.clarity;
          if (!productName) {
            productName = 'id:' + p.diamond_id;
          }
        });
      }
 

      channelData.subtitle = productName;
      channelData.product = this.product;
      if (this.storageService.get('participants')) {
        channelData.user_id = this.user._id;
        // channelData.user_id = this.storageService.get('user_id');
        channelData.participants = this.storageService.get('participants');
        channelData.participants.forEach(u => {
          if (u.type === "Buyer") {
            u.adjustPrice = this.adjustPricebtn;
          }
        });
        const isPresent = channelData.participants.some(name => name.email === this.user.email && name.type === 'Seller');
        if (isPresent) {
          this.user.type = "Seller";
        } else {
          this.user.type = "Buyer";
        }
      } else {
        channelData.participants = [
          { user_id: this.chatWith._id, email: this.chatWith.email, unreadCount: 0, type: 'Seller' },
          { user_id: this.user._id, email: this.user.email, unreadCount: 0, type: 'Buyer', adjustPrice: this.adjustPricebtn }
        ];
      }
    }
    this.httpService.post(environment.SOCKET_ENDPOINT + '/channel/save', channelData).subscribe(
      Response => {
        this.channelInfo = Response['content'];
        if (this.channelInfo && this.channelInfo?.participants) {
          this.channelInfo.participants.forEach(u => {
            if (u.type === "Buyer" && u.adjustPrice === true) {
              this.adjustPricebtn = true;
            }
          });
        }
        this.channelID = this.channelInfo.channel_id;
        this.chatService.joinChannel(this.channelID);
        this.getAllMessage(undefined);
      }, error => {
      });
  }

  sendMessage() {
     this.socket.emit('isUserOnline',{userId: this.chatWith._id});
    if (this.message) {
      const data: any = {
        channel_id: this.channelID, client_id: environment.CLIENT_ID, message: {
          body: this.message,
          isUserActive: this.isUserActive,
          from: { email: this.user.email, senderId: this.user._id, display_name: this.user.display_name || this.user.email },
          to: { email: this.chatWith.email, receiverId: this.chatWith._id, display_name: this.chatWith.display_name || this.chatWith.email },
          timestamp: new Date(Date.now()), status: [{ to: 'unread' }, { from: 'read' }]
        }
      }
      if (this.mazal) {
        data.message.mazal = this.mazal;
      }
      this.chatService.sendMessage(data);
      this.message = '';
      // if(!this.isUserActive){
      //   this.sendNotification(data);
      // }
    }
    this.mazal = undefined;
    this.whitespace = false;
  }

  sendNotification(data) {
    const body = {
      "event_type": "message",
      "client_id": 505050,
      "ToAddresses":[data.message.to.email],
      // "ToAddresses": ["chaudharin1994@gmail.com"],
      "name": data.message.to.display_name || data.message.to.email,
      "email": data.message.to.email,
      "body": data.message.body,
      "websiteUrl": notification.websiteUrl,
      "logo": notification.logo,
      "link": notification.link,
      "supportEmail": notification.supportEmail,
      "supportAddress": notification.supportAddress,
      "sourceName": notification.sourceName
  };
  this.httpService.notification(body)
  }

  back() {
    this.modalController.dismiss({ 'closed': true });
    // this.router.navigateBack('/tabs/messages')
  }

  getAllMessage(event) {
    this.loader = true;
    let client_id = environment.CLIENT_ID;
    let channelID = this.channelID;
    this.httpService.get(environment.SOCKET_ENDPOINT + '/channelmessage/get?channel_id=' + channelID + '&client_id=' + client_id + '&size=' + this.size + '&page=' + this.page).subscribe(
      Response => {
        const response = JSON.parse(JSON.stringify(Response));
        if (response && response.messageCode && response.messageCode === 'OK') {
          this.spinner = false;
          if (response.content) {
            this.loader = false;
            let messages = [];
            if (response.content && response.content.length > 0) {
              messages = response.content.reverse();
              messages.forEach(m => {
                if(m?.message?.body?.indexOf(this.user?.display_name + ' has chosen to buy at') === 0){
                  m.message.body = m.message.body.replace(this.user?.display_name+' has', "You have");
                }
              });
            }            
            this.messages = messages.concat(this.messages);
            this.allMessageCount = response.count;
            if(event){
              event.target.complete();
            }
            // if (this.allMessageCount < 10) {
            //   this.infiniteScroll.disabled = !this.infiniteScroll.disabled;
            // }
            if (this.messages?.length <= 10) {
              setTimeout(() => {
                this.updateScroll();
              }, 10);
            }
          }
        }
      }, error => {

      });
  }

  updateScroll() {
    if (this.content.scrollToBottom) {
      this.content.scrollToBottom(60);
      this.mutationObserver = new MutationObserver((mutations) => {
        this.content.scrollToBottom();
    });
    
    }

  }

  async viewProduct() {
    const modal = await this.modalController.create({
      component: ViewProductPage,
      componentProps: { address: this.product },
      cssClass: 'my-custom-class'
    });
    return await modal.present();
  }

  loadData(event) {
    setTimeout(() => {
      // event.target.complete();
      if (this.messages?.length < this.allMessageCount) {
        this.page = this.page + 1;
        this.getAllMessage(event);
      } else {
        event.target.disabled = true;
      }
    }, 500);
  }

  mazal: any;
  async adjustPrice() {
    const modal = await this.modalController.create({
      component: AdjustPricePage,
      cssClass: 'my-custom-class'
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data?.mazal) {
      this.mazalMessage(data);
      // this.mazal = { value: true, amount: data?.mazal, isshow: true };
      // this.message = "Your offer Price is $ " + data?.mazal;
      // this.sendMessage();
    }
  }

  mazalMessage(data) {
    this.mazal = { value: true, amount: data?.mazal, isshow: true };
    this.message = "Your offer Price is $" + data?.mazal;
    this.sendMessage();
  }

  toggle($event) {
    $event.stopPropagation();
    this.show = !this.show;
  }

  onClick() {
    this.show = false;
  }

  viewProfile() {
    this.router.navigateForward('/view-profile');
  }

  updateCart(offer, mazalmessage) {
    let cartData: any;
    if (!this.adjustPricebtn) {
      this.toasterService.warningToast('This product is not present in your cart');
      return;
    }
    if (this.cartInfo) {
      this.cartInfo.forEach(d => {
        if (d.products[0]?._id === this.product?.products[0]?._id) {
          d.products[0].offerPrice = offer;
          cartData = d;
         
        }
      });
    }

    this.httpService.put(environment.TRADING_ENDPOINT + '/cart/' + cartData._id, cartData).subscribe(
      Response => {
        this.toasterService.successToast('Price updated');
        this.message = this.user.display_name + " has chosen to buy at $" + offer;
        if (mazalmessage) {
          mazalmessage.message.mazal.isshow = false;
          this.updateMessage(mazalmessage);
        }
        setTimeout(() => {
          this.back();
          this.router.navigateForward('/tabs/cart');
        }, 200);
        this.sendMessage();
      }, error => {
        this.toasterService.errorToast('Some error occurred. Please try again.');
      });
  }

  updateMessage(m) {
    this.httpService.put(environment.SOCKET_ENDPOINT + '/channelmessage/update', m).subscribe(
      Response => {
        // this.sendMessage();
      }, error => {
        this.toasterService.errorToast('Some error occurred. Please try again.');
        this.message = null
      });
  }

   hideAdjustPrice = true;
    getCartId(cart) {
    this.httpService.get(environment.TRADING_ENDPOINT + '/cart/user/cart?user_id=' +this.chatWith?._id + '&product_id='+ cart.products[0]._id +'&status=draft').subscribe(Response => {
      const data = JSON.parse(JSON.stringify(Response));
      let iscart = data.content;
      if(iscart && iscart.length>0){
        this.hideAdjustPrice = false;
      } else {
        this.hideAdjustPrice = true;
        // this.toasterService.warningToast('This product no longer exist in buyers cart');
      }
    }, error => {
    });
}


}