import { Component, Input } from '@angular/core';
import { SocketioService } from './socketio.service';
import { HostListener } from "@angular/core";

import { ModalController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AlertController } from '@ionic/angular';

import { StorageService } from './core/services/storage.service';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle'; // import it to your component
// import { Keyboard } from '@ionic-native/keyboard/ngx';
import * as io from 'socket.io-client';
import { environment } from '../environments/environment';
import {
  LocalNotificationActionPerformed, Plugins
} from '@capacitor/core';
import { FcmService } from './services/fcm.services';
import { HttpService } from './services/http.service';
const { LocalNotifications } = Plugins;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  innerWidth: any;
  @Input() type = 'app';
  isPresent: boolean = false;
  socket: any;
  user: any;
  routeChangeEvent: any = {};
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  constructor(
    public service: HttpService,
    public modalController: ModalController,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public storageService: StorageService,
    public router: NavController,
    public alertController: AlertController,
    private socketService: SocketioService,
    private bnIdle: BnNgIdleService,
    private route: Router,
    public fcmService: FcmService,
    public http: HttpService,
  ) {
    this.setUpSocket();
    this.socket = io(environment.SOCKET_ENDPOINT);
    this.socket.on('app-communication', (data) => {
    });
    this.initializeApp();
  }

  ngOnInit(): void {
    this.bnIdle.startWatching(600).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        if (this.route.url.indexOf('signup') !== -1 || this.route.url.indexOf('login') !== -1 || this.route.url.indexOf('forget-password') !== -1) {


        } else if (!this.isPresent) {
          this.presentAlert();
        } else {

        }
      }
    });

  }

  setUpSocket() {
    let url = window.location.search.substring(1);

    (url)
    let qArray = url.split('&');
    for (let i = 0; i < qArray.length; i++) {
      let pArr = qArray[i].split('=');
      if (pArr[0] == 'sessionID') {
        sessionStorage.setItem('session', pArr[1]);
        this.socketService.setupSocketConnection(pArr[1]);
      }
    }
  }

  public isMobile(): boolean {
    return window.innerWidth < 1025;
  }


  async presentAlert() {
    this.isPresent = true;
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Session Expired',
      message: 'Please log in again',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Okay',
          handler: () => {
            this.logout();
          }
        }
      ]
    });

    await alert.present();
  }

  logout() {
    let token = localStorage.getItem('pushToken');
    this.http.put(`${environment.IDENTITY_ENDPOINT}/users/remove_push_token/${token}`, {}).subscribe(
      Response => {
        console.log(Response);
      })

    this.socket.emit('logout', { userId: this.storageService.get('profile') });
    this.isPresent = false;
    sessionStorage.removeItem('tradey-session');
    sessionStorage.clear();
    this.router.navigateBack('/login');
    this.storageService.remove('totalUnread');
    this.storageService.remove('account-info');
    this.storageService.remove('cart-info');
    this.storageService.remove('chatWith');
    this.storageService.remove('wireTransaction');
    this.storageService.remove('company-info');
    this.storageService.remove('shipping');
    this.storageService.remove('money-to');
    this.storageService.remove('money-request');
    this.storageService.remove('product');
    this.storageService.remove('user_id');
    this.storageService.remove('participants');
    this.storageService.remove('tradey-session');

  }


  // initializeApp() {
  //   this.setUpSocket();
  //   this.platform.ready().then(() => {
  //     this.statusBar.styleDefault();
  //     this.splashScreen.hide();
  //   });
  // }

  ngOnDestroy() {
    this.routeChangeEvent.unsubscribe();
  }

  initializeApp() {
    this.splashScreen.show();
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault();
      this.statusBar.styleLightContent();
      // this.splashScreen.hide();
      this.fcmService.initPush();
      // this.generateNotification();

    });
  }

  async generateNotification() {
    await LocalNotifications.requestPermission();
    await LocalNotifications.registerActionTypes({
      types: [
        {
          id: 'ORDER_UPDATE',
          actions: [
            {
              id: 'view',
              title: 'Open order'
            }
          ]
        }
      ]
    });
    LocalNotifications.addListener('localNotificationActionPerformed', async (notification: LocalNotificationActionPerformed) => {
      // alert(JSON.stringify(notification.notification.extra));
      const data = notification.notification.extra;

      const { order_no } = data;
      if (order_no) {
        this.handleOrderUpdate(order_no);
      }
    });
  }

  handleOrderUpdate(order_no) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    // this.orderService.getOrderDetail(order_no).subscribe(res => {
    //   const { content } = res;
    //   console.log(content);

    //   if (content) {
    //     if (content.order_status === 'itemized') {
    //       const clientReq = content.quotation_requested_clients.find(d => d.seller_id === user.user.client_id);
    //       content.distance = clientReq ? clientReq.distance : 0;
    //     }
    //     console.log((content?.distance || content?.selected_quotation.distance));
    //     this.showQuotes(content);
    //   }
    // });

  }

  // async showQuotes(data) {
  //   console.log('data is here', data);
  //   const modal = await this.modalController.create({
  //     component: QuoteDetailsPage,
  //     cssClass: 'my-custom-class',
  //     componentProps: {
  //       data
  //     }
  //   });
  //   await modal.present();
  // }


  isAuthanticat() {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    if (this.user) {
      return true
    } else {
      return false;
    }
  }
  routeChange(path) {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    if (path === 'Orders') {
      this.route.navigate(['dashboard/quotes'], { queryParams: { type: 'quotes' } });
    }
    if (path === 'Log out') {
      this.service.deRegisterPushToke(localStorage.getItem('pushToken'));
      let data = {};
      // let data = {userType: "seller", userId: this.user.user.client_id}
      this.socket.logOut(data);
      this.route.navigate(['/']);
    }
  }


  changeType(event) {
    const element = document.querySelector('#top');
    element.scrollIntoView();
    this.type = event;
  }
}
