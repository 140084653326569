import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-our-platform',
  templateUrl: './our-platform.component.html',
  styleUrls: ['./our-platform.component.scss'],
})
export class OurPlatformComponent implements OnInit {
  @Output() type = new EventEmitter();
  constructor() { }

  ngOnInit() { }

  goTo(path) {
    this.type.emit(path);
  }
}
