import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  contactUsForm: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
      this.contactUsForm = this.formBuilder.group({ 
          firstName: ['', Validators.required],
          subject: ['', Validators.required],
          email: ['', [Validators.required, Validators.email]],   
          messages: ['',Validators.required]
      });
  }

  // convenience getter for easy access to form fields
  get f() { return this.contactUsForm.controls; }

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.contactUsForm.invalid) {
          return;
      }

      // display form values on success
      alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.contactUsForm.value, null, 4));
  }

}
