import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from '../core/services/storage.service';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ToasterServices } from 'src/app/core/services/toaster.service';
import { LoggerService } from '../core/services/logger.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  url: string = 'https://api.itjewelers.com//files/country.json';

  constructor(
    private httpClient: HttpClient,
    public storageService: StorageService,
    public toaster: ToasterServices,
  ) {
    // this.getIPAddress();
  }
  setGlobalHeaders() {
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    if (sessionStorage.getItem('tradey-session') !== undefined) {
      headers.append('authorization', sessionStorage.getItem('tradey-session'));
      // headers.append('client', '1fccd19d207874326a0bee08e5e61089aa4e89b1c2eede653aa0417c9b9e7c6f9b4a83e394859741ddc12229ba57d587a007eaeb107ef56a92eef3a13a228167ef1a6a99adbbdbefacb3ebd8f2b046158bbce198d8e7c0c2c083d7f04eda8a6cb15bcda1ba337cf7b94fc33d95f1bd50e9438e50260ace2715')
    } else {
    }
    return headers;
  }

  GetHttpHeaders(): HttpHeaders {
    if (sessionStorage.getItem('tradey-session') !== undefined && sessionStorage.getItem('tradey-session') !== null) {
      const httpHeader = new HttpHeaders().set('Authorization', `Bearer ${this.getToken}`).
        set('Accept', 'application/json').set('client_id', `${this.clientid}`);
      return httpHeader;
    } else {
      const httpHeader = new HttpHeaders().set('Accept', 'application/json').set('client_id', `${this.clientid}`);
      return httpHeader;
    }
  }

  private get getToken() {
    let token = sessionStorage.getItem('tradey-session');
    // let token = this.storageService.get('tradey-session');
    // console.log('type is here cmameee', typeof(token) );
    // if (typeof(token) === 'string') {
    //   // token = JSON.parse(this.storageService.get('token'));
    // }
    return token;
    // return this.storageService.get('token');
  }

  private get clientid() {
    return environment.CLIENT_ID;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      if (error.status === 409) {
 
        this.toaster.errorToast(error.error.message);
      } else if (error.status === 403) {
   
        this.toaster.errorToast(error.error.message);
      } else if (error.status === 422) {
        this.toaster.errorToast('Insufficient Parameter');
      } else if (error.status === 500) {
        this.toaster.errorToast('Backend server error, please try later');
      }
      // TODO: send the error to remote logging infrastructure
      // console.error('jdfkafkaf',error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(error);
      // return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {

  }

  get(url) {
    const options = this.GetHttpHeaders();
    if (url.indexOf('https') !== -1) {
      return this.httpClient.get(url, { headers: options });
    } else {
      return this.httpClient.get(url, { headers: options });
    }
  }


  getIPAddress() {
    this.httpClient.get<{ ip: string }>('https://jsonip.com')
      .subscribe(data => {
        // console.log('th data', data);
      })
  };

  getAwsRaw(url) {
    const options = this.setGlobalHeaders();
    if (url.indexOf('https') !== -1) {
      return this.httpClient.get(url, { headers: options })
    } else {
      return this.httpClient.get(url, { headers: options })
    }
  }

  postAwsRaw(url, data) {
    const options = this.setGlobalHeaders();
    if (url.indexOf('https') !== -1) {
      return this.httpClient.post(url, data, { headers: options })
    } else {
      return this.httpClient.post(url, data, { headers: options })
    }
  }

  post(url, data) {
    const options = this.GetHttpHeaders();
    return this.httpClient.post(url, data, {
      headers: options
    })
  }

  put(url, data) {
    const options = this.GetHttpHeaders();
    return this.httpClient.put(url, data, {
      headers: options
    })
  }

  remove(url) {
    const options = this.GetHttpHeaders();
    return this.httpClient.delete(url, {
      headers: options
    })
  }

  allCountries(): Observable<any> {
    return this.getAwsRaw(this.url);
  }

  notification(body) {
    fetch(environment.NOTIFICATION_ENDPOINT + '/events/trigger', {
      method: 'post',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(res => res.json())
      .then(json => console.log(json));
  }

  // push notification
  registerPushToke(token) {
    return this.httpClient.put(`${environment.IDENTITY_ENDPOINT}/user/register_push_token/${token}`, {});
}

deRegisterPushToke(token) {    
    return this.httpClient.put(`${environment.IDENTITY_ENDPOINT}/user/remove_push_token/${token}`, {});
}

}
