import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { environment } from '../environments/environment';
import { Observable, Subject } from 'rxjs';
import { StorageService } from './core/services/storage.service';
import { NavController, ModalController } from '@ionic/angular';
import { ChatPage } from './message/chat/chat.page';
import { NotificationPage } from './message/notification/notification.page';
import { EmitEvent, EventStreamService, Events } from './core/services/event-stream.service';
import { HttpService } from './services/http.service';
import { ToasterServices } from './core/services/toaster.service';
@Injectable({
  providedIn: 'root'
})
export class SocketioService {
  sessionId: string;
  socket: any;
  private subject = new Subject<any>();
  data: any;
  user: any;
  private subject1 = new Subject<any>();
  private subject2 = new Subject<any>();
  constructor(public storageService: StorageService, public router: NavController,
    public modalController: ModalController,
    public httpService: HttpService, public toast: ToasterServices,
    public eventStream: EventStreamService,) {
    const user = this.storageService.get('profile');
    if (typeof (user) === 'string') {
      this.user = JSON.parse(this.storageService.get('profile'));
    } else {
      this.user = this.storageService.get('profile');
    }
    this.socket = io(environment.SOCKET_ENDPOINT);
    this.socket.on('connect', this.onConnect);

    this.socket.on('message-channel', (data) => {
      this.subject1.next(data);
    });

    this.socket.on('app-communication', (data) => {
      const isLoginUser = this.isUser();

      if (data.event === 'login') {
        this.subject2.next(data);
      } else if (data.event === 'contact') {
     

        if (isLoginUser) {
          this.sellerContact(data);
        } else {
          this.toast.warningToast('Please login with your tradey account');
        }
      } else if (data.event === 'addtocart') {
        if (isLoginUser) {
          this.addToCart(data?.cart);
        } else {
          this.toast.warningToast('Please login with your tradey account');
        }
      } else if (data.event === 'logout') {
        let token = localStorage.getItem('pushToken');
        this.httpService.put(`${environment.IDENTITY_ENDPOINT}/users/remove_push_token/${token}`, {}).subscribe(
          Response => {
            console.log(Response);
          })
        this.storageService.logout();
      }
    });




    // Contact to seller
    this.socket.on('contact-seller', (data) => {
      // this.modalController.dismiss();
      this.close();
      if (data.loginUser === this.user?._id) {
        if (data.type === 'logOut') {
          sessionStorage.clear();
          this.router.navigateBack('/login');
        } else {
          this.httpService.get(environment.IDENTITY_ENDPOINT + '/users/' + data.chatWith._id).subscribe(
            Response => {
              const userData = JSON.parse(JSON.stringify(Response));
              if (userData.messageCode === 'OK' && userData.content.user) {
                this.router.navigateForward('tabs/messages');
                setTimeout(() => {
                  this.opanChat();
                }, 200);
                userData.content.user.type = 'Seller';
                this.storageService.set('chatWith', userData.content.user, true);
                this.storageService.set('product', data.product);
              } else {
                this.storageService.set('chatWith', data.chatWith, true);
                this.storageService.set('notTradeyUser', data.chatWith, true);
                this.router.navigateForward('tabs/messages');
                setTimeout(() => {
                  this.opanNotification();
                }, 200);
              }
            }, error => {
            });


        }
      }
    });

    // Add to cart
    this.socket.on('add-to-cart', (data) => {
      // this.modalController.dismiss({'closed': true});
      this.close();
      if (data.loginUser === this.user?._id) {
        this.eventStream.emit(new EmitEvent(Events.AddToCart, 'AddToCart'));
        this.router.navigateForward('tabs/cart');
      }
    });
  }



  joinChannel(channel) {


    if (channel) {
      this.socket.emit('join', channel);
    }
  }

  isUser() {
    const ispresent = this.storageService.get('profile');
    if (ispresent) {
      return true;
    } else {
      return false;
    }

  }

  sendMessage(data) {
  

    if (data) {
      this.socket.emit('message-channel', data);
    }
  }
  getMessage(): Observable<any> {
    return this.subject1.asObservable();
  }

  public async close() {
    const modal = await this.modalController.getTop();
    if (modal) {
      modal.dismiss();
    }
  }
  async opanChat() {
    const modal = await this.modalController.create({
      component: ChatPage,
      cssClass: 'my-custom-class'
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data && data['closed'] === true) {
      sessionStorage.removeItem('product');
      this.router.navigateForward('tabs/home');
    }
  }

  async opanNotification() {
    const modal = await this.modalController.create({
      component: NotificationPage,
      cssClass: 'my-custom-class'
    });
    return await modal.present();
  }







  // Session implementation
  authGuard(email) {
 
    this.httpService.get(environment.IDENTITY_ENDPOINT + '/users/find/email?email=' + email).subscribe(
      Response => {
        const userData = JSON.parse(JSON.stringify(Response));

        if (userData.messageCode === 'OK' && userData.content.user) {
        }

      }, error => {

      });
    return false
  }



  onConnect = (socket, d = this.socket) => {
      if (sessionStorage.getItem('session')) {
        this.setupSocketConnection(sessionStorage.getItem('session'));
      }
  }

  setupSocketConnection(session) {
    if (session) {
      this.socket.emit('tradey-session', session);
    }
  }
  isLogin(): Observable<any> {
    return this.subject2.asObservable();
  }

  sellerContact(data) {
    this.httpService.get(environment.IDENTITY_ENDPOINT + '/users/find/' + data?.contact?.chatWith?.email).subscribe(
      Response => {
        const userData = JSON.parse(JSON.stringify(Response));
        if (userData.messageCode === 'OK' && userData?.content?.email) {
          this.router.navigateForward('tabs/messages');
          setTimeout(() => {
            this.opanChat();
          }, 200);
          userData.content.type = 'Seller';
          this.storageService.set('chatWith', userData.content, true);
          this.storageService.set('product', data?.contact?.product);
        } else {
          this.storageService.set('chatWith', data?.contact?.chatWith, true);
          this.storageService.set('notTradeyUser', data?.contact?.chatWith, true);
          // this.router.navigateForward('tabs/messages');
          this.storageService.set('product', data?.contact?.product);
          setTimeout(() => {
            this.opanNotification();
          }, 200);
        }
      }, error => {
      });

  }

  addToCart(data) {
    this.httpService.post(environment.TRADING_ENDPOINT + '/cart', data).subscribe(
      Response => {
        const response = JSON.parse(JSON.stringify(Response));
        if (response && response.messageCode) {
          this.toast.successToast('Product added to cart');
          this.eventStream.emit(new EmitEvent(Events.AddToCart, 'AddToCart'));
          this.router.navigateForward('tabs/cart');
        }
      }, error => {

        if (error?.status === 403) {
          if (error.error.message == 'User is inactive') {
            this.router.navigateForward('/login/popup');
          }
          this.toast.errorToast(error.error.message);
        } else if (error?.status === 422) {
          this.toast.errorToast('Insufficient Parameter');
        } else if (error?.status === 409) {
          this.toast.errorToast(error.error.message);
        } else if (error?.status === 500) {
          this.toast.errorToast('Some error occured.. Please try again.!!');
        }
      });
  }
  // end


}
