import { Injectable } from '@angular/core';
import { Subject, Subscription, Observable, ReplaySubject , empty } from 'rxjs';
import { filter, map, startWith, switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})


export class EventStreamService {

    private subject$ = new ReplaySubject();
    private event$ =  new Subject();
    dataObservable$: Observable<any>;
    on(event: Events, action: any): Subscription {
         return this.subject$
              .pipe(
                    filter((e: EmitEvent) => e && e.name === event),
                    map((e: EmitEvent) => e.value)
                  )
              .subscribe(action);
     }

    emit(event: EmitEvent) {
        this.subject$.next(event);
    }
    ResetEvent() {
     this.subject$.complete();
     this.subject$.unsubscribe();
  }
  unsubscribe(){
    // this.subject$.next();
    // this.subject$.complete();
    // this.subject$.unsubscribe();
  }
   initEvent() {
    this.event$ = new Subject();
    this.dataObservable$ = this.subject$.asObservable();
   }
   emitEvent(data: any) {
    this.event$.next(data);
  }
  getEvent(): Observable<any> {
    return this.event$.asObservable();
  }
}

export class EmitEvent {

  constructor(public name: any, public value?: any) { }

}




export enum Events {
  LOGGED_IN,
  LoggedOut,
  OrderPlaced,
  Subscribed,
  AddToCart,
  CHAT_USER,
  RECENT_ACTIVITY,
  SELECTED_TAB,
  REQUESTMONEY,
  MAZAL
}
